.services {
    background-color: #161615;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        background: linear-gradient(0deg, rgba(22,22,20,0.7) 0%, rgba(22,22,20,0) 100%);
        width: 100%;
        height: 100%;
        top: 0;
        left:0;
        z-index: 0;
    }

    &__content {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 5px;
        grid-row-gap: 5px;
        position: relative;
        z-index: 1;
    }

    &__single {
        position: relative;
        text-align: center;

        a {
            padding: 15px;
            display: block;
            position: relative;
            z-index: 1;

            &:hover h3, &:hover p {
                color: $color-main;
            }
        }

        h2 {
            color: #fff;
            font-size: rem(24px);
            font-weight: 600;
            margin-bottom: 50px;
            transition: ease 0.3s color;
            white-space: nowrap;
        }

        img {
            border-radius: 100%;
            max-width: 245px;
            margin-bottom: 40px;
            box-shadow: 4px -4px #fff;
            width: 100%;
            height: auto;
        }

        p {
            color: #fff;
            line-height: rem(33px);
            font-size: rem(17px);
            max-width: 400px;
            width: 100%;
            margin: 0 auto;
            transition: ease 0.3s color;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            transition: ease 0.3s opacity;
            background: linear-gradient(180deg, rgba(237,232,226,0) 0%, rgba(237,232,226,1) 25%, rgba(237,232,226,1) 75%, rgba(237,232,226,0) 100%);
            z-index: 0;
        }

        &:hover::after {
            opacity: 0.1;

        }

        &::before {
            content: '';
            background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0) 100%);
            height: 100%;
            width: 1px;
            position: absolute;
            top: 0;
            right: -3px;
        }
        
        &:last-of-type::before {
            display: none;
        }
    }
}

@include mq($until: xl) {
    .services {
        &__content {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }
        &__single {
            &:nth-child(2)::before {
                display: none;
            }
        }
    }
}

@include mq($until: sm) {
    .services {
        &__content {
            grid-template-columns: 1fr;
        }
        &__single {
            &:nth-child(2)::before {
                display: block;
            }
            &::before {
                background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 100%);
                height: 1px;
                width: 100%;
                top: unset;
                bottom: -3px;
                right: 0;
            }
            a {
                padding: 30px 20px;
            }
            h2 {
                white-space: normal;
            }
        }
    }
}

