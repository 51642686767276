.about {
    position: relative;
    background-color: #161615;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    padding-top: 8vh;
    padding-bottom: 8vh;
    min-height: 60vh;
    display: flex;
    align-items: center;
    @include mq($until: md) {
        min-height: unset;
    }

    &__content {
        max-width: 800px;
        width: 100%;
        margin: 0 auto;
        text-align: center;
        position: relative;
        z-index: 1;

        h2 {
            color: $color-main;
            font-size: rem(45px);
            font-weight: 600;
            margin-bottom: 50px;
            @include mq($until: md) {
                font-size: rem(40px);
                line-height: rem(41px);
            }
            @include mq($until: sm) {
                font-size: rem(30px);
                line-height: rem(31px);
            }
        }
        p {
            color: #fff;
            line-height: rem(33px);
        }
    }
    &::after {
        content: '';
        position: absolute;
        background: linear-gradient(0deg, rgba(22,22,20,1) 0%, rgba(22,22,20,1) 30%, rgba(22,22,20,0.7) 100%);
        width: 100%;
        height: 100%;
        top: 0;
        left:0;
        z-index: 0;
    }
    /*&::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        bottom: 0;
        left: 0;
        background: url(/themes/default/assets/images/fala-sekcja-3-01.svg);
        background-position: center bottom;
        background-size: contain;
        background-repeat: no-repeat;
    }*/
}
