.hero {
    min-height: 100vh;
    background-color: #161615;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    position: relative;
    display: flex;
    padding-top: 280px;
    &::after {
        content: '';
        position: absolute;
        background: linear-gradient(165deg, rgba(22,22,20,1) 0%, rgba(22,22,20,0.9) 32%, rgba(22,22,20,0.22) 40%);
        width: 100%;
        height: 100vh;
        left:0;
        top: 0;
        z-index: 0;
    }
    &__boat {
        position: absolute;
        background-image: url('/themes/default/assets/images/top-boat.webp');
        background-repeat: no-repeat;
        background-position: right 80%;
        background-size: contain;
        width: 55%;
        height: 100vh;
        top: 0;
        right: 0;
        z-index: 2;
        @include mq($until: md) {
            display: none;
        }
    }
    .header__widget {
        display: none;
        margin-bottom: 30px;
    }
    &__content {
        position: relative;
        z-index: 2;
        h1 {
            color: #BC8D40;
            font-size: rem(55px);
            font-weight: 500;
            line-height: rem(56px);
            margin: 0 0 30px 0;
            max-width: 700px;
            span {
                font-size: rem(38px);
                color: #BC8D40;
            }
        }
        a {
            background: linear-gradient(0deg,#7b5c2a,#c99b4f);
            padding: 15px 30px;
            border-radius: 5px;
            color: #fff;
            font-size: 1.25rem;
            font-weight: 500;        
        }
        p {
            color: #fff;
            font-size: rem(31px);
            font-style: italic;
            line-height: rem(42px);
            letter-spacing: -1px;
            padding: 0 50px;
            max-width: 600px;
            width: 100%;
            position: relative;
            &:first-of-type {
                padding: 50px;
            }
            &:after {
                display: none;
            }
            &:first-of-type:after {
                display: block;
            }
            &::after {
                content: '';
                background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0) 100%);
                height: 2px;
                width: 100%;
                position: absolute;
                top: 10px;
                left: 0;
            }
        }
    }
}

@include mq($until: 1634px){
    .hero {
        padding-top: 300px;
    }
}

@include mq($until: md){
    .hero {
        padding-top: 140px;
        min-height: unset;
        justify-content: center;
        text-align: center;
        &::before { 
            background-position: right 90%;
            width: 80%;        
        }
        .header__widget {
            display: block;
        }    
        &__content {
            max-width: 570px;
            padding-right: 50px;
            h1 {
                font-size: rem(40px);
                line-height: rem(41px);
                span {
                    font-size: rem(34px);
                }
            }
            p:first-of-type {
                padding: 50px 0;
            }
        }
    }
}

@include mq($until: sm){
    .hero {
        &::before { 
            background-position: right 90%;
            width: 85%;        
        }
        &__content {
            padding-right: 30px;
            h1 {
                font-size: rem(30px);
                line-height: rem(31px);
                margin-bottom: 0;
                &:last-of-type {
                    margin-bottom: 20px;
                }
                span {
                    font-size: rem(26px);
                }
            }
            p {
                font-size: 1.6375rem;
                line-height: 2.025rem;
            }        
        }
    }
}

@include mq($until: xs){
    .hero {
        &::before { 
            background-position: right 90%;
            width: 90%;        
        }
        &__content {
            padding-right: 15px;
            p {
                padding: 40px 0 0 0;
            }
        }
    }
}
