.subsite {
    .sub-hero {
        background-color: #161615;
        background-size: cover;
        background-attachment: fixed;
        background-position: center;
        position: relative;
        padding-top: 340px;
        padding-bottom: 30px;
        @include mq($until: 1776px) {
            padding-top: 380px;
        }
        @include mq($until: md) {
            padding-top: 25vw;
        }
        @include mq($until: 480px) {
            padding-top: 120px;
        }
        &:after {
            content: '';
            background-image: url(/themes/default/assets/images/fala-subsite.svg);
            background-repeat: no-repeat;
            background-position: bottom center;
            background-size: 101%;
            display: block;
            position: absolute;
            bottom: -1px;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 0;
        }
        &::before {
            content: '';
            position: absolute;
            background: linear-gradient(165deg, rgba(22,22,20,1) 0%, rgba(22,22,20,0.9) 32%, rgba(22,22,20,0.22) 40%);
            width: 100%;
            height: 100%;
            left:0;
            top: 0;
            z-index: 0;
        }    
        &__content {
            position: relative;
            z-index: 2;
            width: 100%;
            display: grid;
            grid-template-columns: 4fr 2fr;
            grid-column-gap: 50px;
            justify-content: space-between;
            align-items: center;
            @include mq($until: md) {
                grid-template-columns: 1fr;
            }
            h1 {
                color: #fff;
                font-size: rem(55px);
                font-weight: 600;
                line-height: rem(56px);
                margin: 0 0 370px 0;
                max-width: 780px;
                @include mq($until: md) {
                    margin: 0 0 25vw 0;
                    font-size: rem(40px);
                    line-height: rem(41px);
                    text-align: center;
                }
                @include mq($until: sm) {
                    font-size: rem(30px);
                    line-height: rem(31px);
                }
                @include mq($until: 480px) {
                    margin: 0 0 20vw 0;
                }        
            }
            img {
                border-radius: 100%;
                height: auto;
                max-width: 480px;
                width: 100%;
                margin: 0 auto;
                box-shadow: 7px -11px $color-main;
                @include mq($until: md) {
                    max-width: 380px;
                    margin-bottom: 80px;
                    display: none;
                }
            }
        }    
    }
    .content {
        padding-top: 0;
        padding-bottom: 100px;
        background-position: center;
        background-repeat: no-repeat;
        background-attachment: fixed;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100px;
            background: linear-gradient(180deg, #fff 0%, transparent 100%);
        }
        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100px;
            background: linear-gradient(0deg, #fff 0%, transparent 100%);
        }
        h2 {
            font-size: rem(44px);
            font-weight: 600;
            margin-bottom: 40px;
        }
        h3 {
            font-size: rem(26px);
            font-weight: 700;
            margin-bottom: 30px;
        }
        p {
            line-height: rem(32px);
            margin-bottom: 25px;
        }
        img {
            border-radius: 9px;
            margin: 0 auto;
            display: block;
            &.fr-fil {
                margin: 0 auto 0 0;
            }
        }
        li {
            padding-left: 50px;
            line-height: rem(32px);
            &:before {
                top: 10px;
                border: 0;
            }
            b {
                font-weight: rem(20px);
            }
        }
        .main-content {
            position: relative;
            z-index: 1;
        }
        .kolory {
            .list {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                text-align: center;
                gap: 20px;
                @include mq($until: lg) {
                    grid-template-columns: repeat(3, 1fr);
                }    
                @include mq($until: sm) {
                    grid-template-columns: repeat(2, 1fr);
                }    
                @include mq($until: 400px) {
                    grid-template-columns: 1fr;
                }    
                p {
                    margin-top: 10px;
                    font-weight: 700;
                }
            }
        }
        &.sidebar {
            display: grid;
            grid-template-columns: 4fr 2fr;
            grid-column-gap: 50px;
            @include mq($until: md) {
                grid-template-columns: 1fr;
            }
            .main-content {
                max-width: 920px;
                padding-right: 50px; 
                @include mq($until: lg) {
                    padding-right: 0; 
                }
            }
            .sticky {
                position: relative;
                z-index: 1;
                top: 0;
                display: inline-block;
                width: 100%;
                background-color: #fff;
                box-shadow: 0 3px 16px rgba(0, 0, 0, 0.04);
                padding: 60px;
                border-radius: 9px;
                @include mq($until: lg) {
                    padding: 50px; 
                    top: 0!important;
                }
                &-column {
                    max-width: 370px;
                    margin: 100px auto 0 auto;                
                }
                &-wrapper {
                    height: 100%;
                }
                .title, h4 {
                    font-size: rem(21px);
                    font-weight: 700;
                    margin-bottom: 40px;
                }
                ul {
                    margin: 0;
                }
                li {
                    margin: 0;
                    padding: 0;
                    &:before {
                        display: none;
                    }
                }
                a {
                    display: block;
                    color: $paragraph-color;
                    padding: 10px 0;
                    transition: ease 0.3s color;
                    font-weight: 500;
                    span {
                        border-bottom: 1px solid #fff;
                        transition: ease 0.3s all;
                    }
                    &:hover span, &.highlighted span {
                        color: $color-main;
                        border-color: $color-main;
                    }
                }
            }
            .section {
                margin-bottom: 80px;
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }
}

#mapName {
    height: 80vh;
}
