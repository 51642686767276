.header__hamburger {
  display: none;
  width: 30px;
  height: 25px;
  position: relative;
  justify-self: flex-end;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;

  &--fullscreen {
    display: block;
    z-index: 2;
  }

  @media screen and (max-width: 991px) {
    display: block;
  }

  span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background-color: $color-main;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;

    &:nth-child(1) {
      top: 0;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }

    &:nth-child(2) {
      top: 9px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }

    &:nth-child(3) {
      top: 18px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }
  }

  &--open {
    span {
      &:nth-child(1) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        top: -3px;
        left: 8px;
      }

      &:nth-child(2) {
        width: 0;
        opacity: 0;
      }

      &:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        top: 18px;
        left: 8px;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .header {
    &__content {
      z-index: 9;
      position: relative;
      background: #161615;  
    }
    &__nav {
      position: absolute!important;
      width: 100%;
      top: 0;
      right: 0;
      height: 100vh;
      background: $color-main;
      z-index: 3;
      transform-origin: 0 0;
      transform: translate(100%, 0);
      transition: transform .5s ease-in-out;
      padding-top: 110px;
      padding-bottom: 15px;
      margin-right: 0!important;
      &::after {
        display: none;
      }
      &--visible {
        transform: translate(0, 0);
        overflow: auto;
      }
    }

    &__menuLink {
      font-size: rem(20px)!important;
      font-weight: 700!important;
    }

    &__menuList {
      flex-direction: column;
      padding: 10px 0;
      position: static;
      align-items: flex-start;

      & > .header__menuItem {
        display: block;
        margin: 0 0 15px 0;

        &--fullscreen {
          display: flex;
          margin: 0 0 25px 0;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &--hasSubmenu > a::after {
          float: right;
        }

        &.megamenu .header__submenu {
          padding: 20px 20px 20px 20px;
          display: block;
          border: 1px solid #be974c;
          background: #fff;
          border-radius: 5px;
          max-height: unset;
          span {
            color: #161614;
            margin-bottom: 10px;
            display: block;
          }
          .header__menuLink {
            color: #161614;
            display: block;
            margin: 0;
            padding: 10px 15px;
          }
          .column:first-of-type {
            padding-left: 0;
          }
          .big {
            margin-bottom: 0!important;
          }
          .big .header__menuLink {
            font-size: rem(20px)!important;
            font-weight: 700!important;
            padding: 10px 0;
          }
          .header__column {
            margin-top: 10px;
          }
        }
      }
    }

    &__submenu {
      position: static;
      padding: 10px 0 0 0;
      margin-top: 5px;
      padding: 20px 20px 20px 20px;
      display: block;
      border: 1px solid #be974c;
      background: #fff;
      border-radius: 5px;

      &--fullscreen {
        background-color: transparent;
        justify-content: center;
        align-items: center;
      }

      &--hidden {
        display: none!important;
      }

      .header__menuItem {
        display: block;
        margin: 0 0 15px 0;
        &:last-of-type {
          margin: 0;
        }
      }

      .header__menuLink {
        margin: 0 10px 10px 10px;
        display: block;
        margin: 0;
        padding: 10px 15px;
        font-weight: 500!important;
        color: #161614;
      }
    }
  }
}

@media screen and (max-width: 767px) {

}

@media screen and (max-width: 575px) {

}
