.partners {
    &__state {
        margin-top: 100px;
        h1 {
            font-size: rem(34px);
        }
    }
    &__list {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 40px;
        row-gap: 40px;
    }
    &__single {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        padding: 20px;
        border: 1px solid rgba(23, 23, 20, 0.12);
        border-radius: 10px;
        transition: ease 0.3s all;
        &:hover {
            border-color: $color-main;
        }
        &-head {
            display: grid;
            grid-template-columns: 80px 1fr;
            column-gap: 20px;
            align-items: center;
            .logo {
                max-width: 80px;
            }
        }
        h4 {
            margin: 0!important;
        }
        p {
            margin: 0!important;
        }
        a {
            &.contact {
                display: block;
            }
            &.button {
                display: block;
                width: 100%;
                margin-top: 10px
            }
            svg {
                fill: $color-main;
                width: 25px;
                height: auto;
            }
        }
        .cat, .socials {
            display: flex;
            column-gap: 5px;
            row-gap: 5px;
            flex-wrap: wrap;
            text-transform: uppercase;
        }
        .socials img {
            display: inline-block!important;
        }
        span {
            background: $color-main;
            color: #fff;
            padding: 5px 10px;
            border-radius: 3px;
            font-size: 12px;
        }
    }
}


.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
    background: white;
    color: #161615;
    box-shadow: none;
    border-radius: 5px;
    border: 1px solid $color-main;
}
.leaflet-popup-content {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin: 20px;
    h4 {
        margin: 0!important;
        font-size: 20px;
        color: #161615;
    }
    span {
        background: $color-main;
        color: #fff;
        padding: 5px 10px;
        border-radius: 3px;
        font-size: 12px;
    }
    p {
        font-size: 15px;
        color: #161615;
        margin: 0!important;
        line-height: 22px!important;
    }
    a {
        color: $color-main;
        font-size: 15px;
        &.contact {
            display: block;
        }
    }
    .socials img {
        display: inline-block!important;
        width: 25px;
    }
    .button {
        color: #fff;
        width: 100%;
        margin-top: 10px
    }
    .cat, .socials {
        display: flex;
        column-gap: 5px;
        row-gap: 5px;
        flex-wrap: wrap;    
    }
}
.leaflet-container a.leaflet-popup-close-button {

}
.leaflet-control-layers-toggle {
    background-image: url('/themes/default/assets/images/icons/layers.png');
}
.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
    box-shadow: none;
    border: 1px solid $color-main;
}
