.button {
  background: linear-gradient(0deg, #7B5C2A 0%, #C99B4F 100%);
  padding: 15px 15px;
  border-radius: 5px;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  border: 0;

  transition-property: color, border, background-color;
  transition-duration: .3s;
  transition-timing-function: ease-in-out;
  display: inline-block;
  line-height: 1;
  text-align: center;
  align-self: flex-start;
  cursor: pointer;

  &:hover {
    background-color: transparent;
    color: #fff;
  }

  &--secondary{
    background-color: transparent;
    color: $color-main;

    &:hover {
      color: $color-font-light;
      background-color: $color-main;
    }
  }
}
