.article {
	display: flex;
	align-items: center;
	position: relative;
	margin-bottom: $padding-xl;

	.bg-image {
		position: absolute;
		right: -8vw;
		z-index: -1;
		opacity: 0.2;
		filter: grayscale(1);
		transition: ease 0.3s filter;

		height: 100vh;
		width: 60%;
		background-size: contain;
		background-position: center left;
		background-repeat: no-repeat;
		top: 50%;
		transform: translateY(-50%);
		&.active {
			filter: grayscale(0);
		}
	}

	&__image {
		padding-right: 100px;
		position: relative;

		img {
			max-width: 414px;
			width: 100%;
			object-fit: cover;
			object-fit: contain;
			width: 100%;
			height: auto;
			border-radius: 100%;
			box-shadow: -7px -11px #161615;
		}
		/*&::after {
			content: '';
			width: 100%;
			max-width: 414px;
			height: 100%;
			border-radius: 100%;
			box-shadow: -7px -11px #161615;
			position: absolute;
			left: 0;
			top: 0;
		}*/
  	}

	&__text {
		max-width: 600px;
		width: 100%;
		z-index: 1;

		h3 {
			font-size: rem(44px);
			font-weight: 600;
			margin-bottom: 30px;
		}

		p {
			line-height: rem(28px);
		}

	}

	.link {
		margin-left: auto;
		margin-top: 40px;
		min-width: 160px;
		display: block;
		width: fit-content;
		font-size: rem(22px);
		font-style: italic;
		font-weight: 600;
		text-decoration: underline;
		letter-spacing: -1px;
	}

	@include mq($until: md) {

		flex-direction: column;
		margin-bottom: $padding-lg;

		&__text, &__image {
			width: 100%;
			padding: 0;
			max-width: 550px;
		}

		
		img {
			height: auto;
		}

	}

	&.to-right {
		justify-content: flex-end;

		.bg-image {
			left: -8vw;
			right: unset;
		}
		.article__image  {
			padding-right: 0;
			padding-left: 100px;
			order: 1;
			img {
				box-shadow: 7px -11px $color-main;
			}
		}
	}

}

@keyframes spin {
	0% {
	  margin-left: 0;
	}
	100% {
	  margin-left: -1920px;
	}
  }

  
@include mq($until: xl) {
	.article {
		.bg-image {
			right: -100px;
		}
		&.to-right {
			.bg-image {
				left: -100px;
				right: unset;
			}
		}
	}
}

@include mq($until: md) {
	.article {
		.bg-image {
			width: 100%;
		}
		&__image {
			order: 0;
			padding-left: 0;
			img {
				margin: 0 auto 50px auto;
				display: block;
				max-width: 280px;
			}
		}
		&__text {
			text-align: center;
		}
		.link {
			margin-right: auto;
		}
		&.to-right {
			.article__image {
				order: 0;
				padding-left: 0;
			}
		}
	}
}
