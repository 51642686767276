.offer {
    position: relative;
    min-height: 100vh;
    padding-top: 40vh;
    overflow: hidden;

    &:after {
        content: '';
        background-image: url(/themes/default/assets/images/fala-sekcja-3-01.svg);
        background-repeat: no-repeat;
        background-position: top center;
        background-size: 101%;
        display: block;
        position: absolute;
        top: -1px;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    &:before {
        content: '';
        background: linear-gradient(0deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
        position: absolute;
        height: 150px;
        width: 100%;
        bottom: 0;
        left: 0;
        z-index: 1;
    }
    &__content {
        position: relative;
        z-index: 2;
        &#plasdeck {
            z-index: 5;
        }
        &#teakowe {
            z-index: 4;
        }
        &#eva {
            z-index: 3;
        }
        h3 {
            @include mq($until: md) {
                font-size: rem(40px);
                line-height: rem(41px);
            }
            @include mq($until: sm) {
                font-size: rem(30px);
                line-height: rem(31px);
            }
        }
    }
}

@include mq($until: md) {
    .offer {
        padding-top: 100px;
        &__content {
            margin-bottom: 100px;
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}
