/*.waves { 
    height: 339px;
    width:100%;
    position:absolute;
    bottom:0;
    left:0;
    overflow: hidden;

    &.top {
        height: 240px;
        top: 0;
        bottom: unset;
        .wave {
            top: 0;
            bottom: unset;
            height: 240px;
            background: url('/themes/default/assets/images/fala-stopka-top.svg') repeat-x; 
        }
    }

    .wave {
        background: url('/themes/default/assets/images/fala-stopka.svg') repeat-x; 
        position: absolute;
        bottom: 0;
        width: 3840px;
        height: 199px;
        animation: wave 18s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
        transform: translate3d(0, 0, 0);
        z-index: 1;
    }

    .wave:nth-of-type(2) {
        bottom: 0;
        animation: wave 18s cubic-bezier( 0.36, 0.45, 0.63, 0.53) -.125s infinite, swell 7s ease -1.25s infinite;
        opacity: 1;
        background: url('/themes/default/assets/images/fala-stopka-color.svg') repeat-x; 
        z-index: 0;
    }
}


@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1920px;
  }
}

@keyframes swell {
  0%, 100% {
    transform: translate3d(0,-12px,0);
  }
  50% {
    transform: translate3d(0,0,0);
  }
}

#waves {
    animation: MoveWave 20s linear infinite;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100vw;
    background-size: cover;
    background-position: center bottom;
    transition: all 3s;
    pointer-events: none;
    background-repeat: no-repeat;
}

@keyframes MoveWave {
    0% {
        transform: scale(1);
        opacity: 0;
    }
    10% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    70% {
        transform: scale(1.3);
        opacity: 1;
    }
    90% {
        transform: scale(1.4);
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}
*/

.waves {
    position: absolute;
    width: 100%;
    height: 339px;
    bottom: 0;
    left:0;
    overflow: hidden;
    
    &:before {
        content:'';
        background-image: url("/themes/default/assets/images/fala-stopka.svg");
        background-position: 0 bottom;
        width: 100%;
        height: 100%;
        position: relative;
        display: block;
        background-repeat: repeat-x;
        animation: move 40s linear infinite;
        -moz-animation: move 40s linear infinite;
        -webkit-animation: move 40s linear infinite;
        -ms-animation: move 40s linear infinite;
        -o-animation: move 40s linear infinite;
        position: absolute;
        bottom: 0;
        z-index: 1;
    }
    &:after {
        content:'';
        background-image: url("/themes/default/assets/images/fala-stopka-color.svg");
        background-position: 0 bottom;
        width: 100%;
        height: 100%;
        position: relative;
        display: block;
        background-repeat: repeat-x;
        animation: move 40s linear infinite, swell 7s ease -1.25s infinite;
        -moz-animation: move 40s linear infinite, swell 7s ease -1.25s infinite;
        -webkit-animation: move 40s linear infinite, swell 7s ease -1.25s infinite;
        -ms-animation: move 40s linear infinite, swell 7s ease -1.25s infinite;
        -o-animation: move 40s linear infinite, swell 7s ease -1.25s infinite;
        position: absolute;
        bottom: -2px;
        z-index: 0;
    }
    &.top {
        height: 174px;
        top: 0;
        bottom: unset;
        &:before {
            background-image: url("/themes/default/assets/images/fala-stopka-top.svg");
            animation: move 38s linear infinite;
            -moz-animation: move 38s linear infinite;
            -webkit-animation: move 38s linear infinite;
            -ms-animation: move 38s linear infinite;
            -o-animation: move 38s linear infinite;    
        }
        &:after {
            display: none;
        }
    }
}

@keyframes move {
	0% { background-position: 0 bottom; }
	100% { background-position: -1920px bottom; }
}
@-moz-keyframes move {
	0% { background-position: 0 bottom; }
	100% { background-position: -1920px bottom; }
}
@-webkit-keyframes move {
	0% { background-position: 0 bottom; }
	100% { background-position: -1920px bottom; }
}
@-ms-keyframes move {
	0% { background-position: 0 bottom; }
	100% { background-position: -1920px bottom; }
}
@-o-keyframes move {
	0% { background-position: 0 bottom; }
	100% { background-position: -1920px bottom; }
}
@keyframes swell {
    0%, 100% {
      transform: translate3d(0,-12px,0);
    }
    50% {
      transform: translate3d(0,0,0);
    }
}
@-moz-keyframes swell {
    0%, 100% {
      transform: translate3d(0,-12px,0);
    }
    50% {
      transform: translate3d(0,0,0);
    }
}
@-webkit-keyframes swell {
    0%, 100% {
      transform: translate3d(0,-12px,0);
    }
    50% {
      transform: translate3d(0,0,0);
    }
}
@-ms-keyframes swell {
    0%, 100% {
      transform: translate3d(0,-12px,0);
    }
    50% {
      transform: translate3d(0,0,0);
    }
}
@-o-keyframes swell {
    0%, 100% {
      transform: translate3d(0,-12px,0);
    }
    50% {
      transform: translate3d(0,0,0);
    }
}
