.bread-crumb {
  /*display: flex;
  justify-items: flex-start;*/
  padding-top: 50px;
  padding-bottom: 10px;
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  position: relative;
  z-index: 2;
  @include mq($until: md){
    display: none;
  }

  &__item {
    /*display: flex;
    justify-items: flex-start;*/
    font-size: rem(16px);
    font-weight: 600;
    color: #fff;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 10px;
    display: inline-block;
    &:not(:last-child) {
      margin-right: 3px;
      &.home img {
        display: none;
        @include mq($until: xs) {
          display: inline;
        }
      }
      @include mq($until: xs) {
        &.home::after {
          display: none;
        }
          
      }

      &::after {
        content: "/";
        color: #fff;
        align-self: center;
        margin-left: 10px;
        margin-right: 5px;
        font-weight: 600;
        display: inline;

        @media screen and (max-width: 1499px) {
          margin-top: 0;
        }

        @media screen and (min-width: 1921px) {
          margin-top: -5px;
        }
      }
    }

    &::before {
      display: none;
    }

    a {
      font-size: .9rem;
      display: inline;
      align-items: center;
      color: #fff;
      font-weight: 600;
      transition: color .3s;
      span {
        color: #fff;
        font-size: .9rem;
        font-weight: 600;
        transition: color .3s;
        @include mq($until: xs) {
          display: none;
        }
      }
      &:hover, &:hover span {
        color: $color-main;
      }

      img {
        max-width: 30px;
        max-height: 24px;
        margin-right: 5px;
        margin-top: -4px;

        @media screen and (min-width: 1921px) {
          margin-top: -1px;
        }
      }
    }

    &.active {
      color: $color-main;
      a {
        color: $color-main;
      }
    }
  }
}
