.realizations {
    &__all {
        display: grid;
        grid-template-columns: 400px 1fr;
        column-gap: 40px;
        align-items: start;
        @include mq($until: md) {
            grid-template-columns: 1fr;
            margin-top: 40px;
        }
    }
    &__show-filters {
        display: none;
        @include mq($until: md) {
            display: inline-block;
        }
    }

    &__filters {
        @include mq($until: md) {
            display: none;
            position: fixed;
            top: 0;
            left: 0;
            background-color: #fff;
            z-index: 99999;
            padding: 20px;
            overflow: auto;
            width: 100%;
            height: 100%;
        }
        .close {
            display: none;
            position: fixed;
            top: 20px;
            right: 20px;
            transition: ease 0.3s all;
            svg {
                width: 25px;
            }
            line {
                stroke:#161615;
            }
            @include mq($until: md) {
                display: block;
            }
        }
        div {
            display: flex;
            flex-wrap: wrap;
            row-gap: 5px;
            column-gap: 5px;
            margin-bottom: 20px;
            &:last-of-type {
                margin-bottom: 0;
            }
            &.colors {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                column-gap: 5px;
                row-gap: 5px;
                @include mq($until: 400px) {
                    grid-template-columns: 1fr;
                }
                span {
                    display: block;
                    text-align: center;
                    @include mq($until: 400px) {
                        display: flex;
                        text-align: left;
                        align-items: center;
                        img {
                            margin-left: 0;
                            margin-right: 15px;
                            margin-bottom: 0!important;
                        }
                    }
                }
            }
        }
        input {
            display: none;
            &:checked + span {
                background-color: $color-main;
                color: #fff;
            }
        }
        span {
            color: #161615;
            padding: 5px 10px;
            border-radius: 3px;
            font-size: 12px;
            background-color: #fff;
            text-transform: uppercase;
            display: inline-block;
            cursor: pointer;
            box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.14);
        }
        img {
            border-radius: 3px!important;
            margin-bottom: 5px!important;
        }
    }

    &__list{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 30px;
        @include mq($until: xl) {
            grid-template-columns: repeat(3, 1fr);
        }
        @include mq($until: sm) {
            grid-template-columns: repeat(2, 1fr);
        }
        @include mq($until: 400px) {
            grid-template-columns: 1fr;
        }
        &__header-wrapper{
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
        &__header{
            margin-bottom: 30px;
            &--grid{
                grid-column-start: span 4;
                margin-bottom: 0;
            }
        }
        &__link{
            color: $color-main;
        }
    }
    &__card{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        &-header{
            font-size: 1.2rem;
            margin-top: 15px;
            margin-bottom: 0!important;
        }
    }

    &__tags {
        strong {
            margin-right: 5px;
            @include mq($until: md) {
                display: block;
            }
        }
        span {
            color: #fff;
            padding: 5px 10px;
            border-radius: 3px;
            font-size: 12px;
            background-color: $color-main;
            text-transform: uppercase;
            margin-right: 5px;
            display: inline-block;
            @include mq($until: md) {
                margin-bottom: 2px;
                margin-top: 2px;
            }

        }
    }
}

.filters-visible {
    .realizations__filters {
        display: block;
    }
    .subsite .content .main-content {
        z-index: 99999;
    }
}
