div#flags {
  margin-left: 30px;
  position: relative;
  z-index: 9;

  a {
    padding: 5px 10px;
    display: block;
    &:hover span {
      color: $color-main;
    }
  }

  img {
      width: 25px;
      margin-right: 10px;
  }

  span {
    color: #fff;
    font-size: rem(17px);
    font-weight: 600;
    transition: ease 0.3s color;
  }

  .active-lang  {
      img {
          opacity: 1;
          box-shadow: 0 0 6px #00000040;
      }
  }

  .selected {
    border: 1px solid #fff;
    border-radius: 5px;
    position: relative;
    min-width: 110px;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1;
      cursor: pointer;
    }
    &::before {
      content: '';
      background-image: url(/themes/default/assets/images/icons/arrow-white.svg);
      width: 12px;
      height: 12px;
      display: inline-block;
      margin-left: 15px;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      transition: ease 0.3s transform;
    }

  }
  .dropdown {
    opacity: 0;
    transform: scaleY(0);
    position: absolute;
    z-index: 1;
    background: #161615;
    top: 50px;
    border-radius: 5px;
    height: auto;
    transform-origin: top center;
    transition: opacity .6s ease-in-out, transform .3s ease-in-out;
    width: 100%;
  }
  &.opened {
    .dropdown {
      opacity: 1;
      transform: scaleY(1);
    }
    .selected::before {
      transform: translateY(-50%) rotate(180deg);
    }
  }
}

@include mq($until: md) {

}
