.banner {
    min-height: 100vh;
    display: flex;
    position: relative;
    align-items: center;
    background-size: cover;
    background-position: 36% center;
    &__content {
        z-index: 1;
        h2 {
            font-size: rem(39px);
            line-height: rem(50px);
            font-weight: 600;
            font-style: italic;
            letter-spacing: -1px;
            margin-bottom: 30px;
            @include mq($until: sm) {
                font-size: rem(30px);
            }
        }
        a {
            background: linear-gradient(0deg, #7B5C2A 0%, #C99B4F 100%);
            padding: 15px 30px;
            border-radius: 5px;
            color: #fff;
            font-size: rem(20px);
            font-weight: 500;
            margin-left: auto;
            display: block;
            width: -webkit-fit-content;
            width: -moz-fit-content;
            width: fit-content;
        }
    }
}
@include mq($until: md) {
    .banner {
        min-height: 70vh;
        &__content a {
            margin-left: 0;
        }
    }
}
