.faq {

    &__question {
        font-weight: 600;
        font-size: rem(24px);
        cursor: pointer;
        color: $paragraph-color;
        margin-bottom: 10px;
        transition: ease 0.3s color;
        &.active, &:hover {
            color: $color-main;
            svg {
                fill: $color-main;
                stroke: $color-main;
            }
        }
        &:last-of-type {
            margin-bottom: 0;
        }
        svg {
            transition: ease 0.3s all;
            fill: $paragraph-color;
            stroke: $paragraph-color;
        }
    }
    &__answear {
        display: none;
        &.active {
            display: block;
        }
    }

    &-icons {
        line {
            transition: ease 0.3s all;
        }
        .active .hide {
            opacity: 0;
        }
        .faq__question {
            display: flex;
            align-items: center;
            svg {
                margin-left: 10px;
            }
        }
        &.left {
            svg {
                order: -1;
                margin-left: 0;
                margin-right: 10px;
            }
        }
        &.space .faq__question {
            justify-content: space-between;
        }
        &.arrow .faq__question.active {
            svg {
                transform: rotate(180deg);
                fill: $color-main;
            }
        }
    }
}


