.footer {
  background-color: #161615;
  padding-top: 8vh;
  padding-bottom: 8vh;
  position: relative;

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__contact {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-template-rows: 1fr;
    grid-column-gap: 50px;
    justify-items: end;
    align-items: center;

    div {
      color: #fff;
      list-style: none;
      &.address {
        padding-left: 20px;
        text-align: right;
      }
    }
    span {
      color: #fff;
      display: inline-block;
    }
    a {
      color: #fff;
      white-space: nowrap;
      &:hover {
        color: $color-main;
      }
    }
    img {
      margin-right: 10px;
    }
    .social {
      display: flex;
      a {
        opacity: 0.5;
        transition: ease 0.3s opacity;

        &:hover {
          opacity: 1;
        }
      }
      svg {
        fill: #fff;
        margin-right: 10px;
      }
    }
  }

  &__logo {
    max-width: 300px;
    width: 100%;
    flex: 0 1 20%;
    img {
      width: 100%;
    }
  }

  &__widgets {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    margin-top: 60px;
  }
  &__widget {
    a {
      display: flex;
      padding: 15px;
      border-radius: 5px;
      transition: ease 0.3s background-color;
      &:hover {
        background-color: rgba(255, 255, 255, 0.05);
      }  
    }
    img {
      margin-right: 20px;
      max-width: 95px;
      width: 100%;
    }
    strong {
      color: #fff;
      font-size: rem(16px);
      font-weight: 600;
      font-style: italic;
      padding-bottom: 10px;
      border-bottom: 1px solid $color-main;
      margin-bottom: 20px;
      display: inline-block;

    }
    p, span {
      color: #8B8B8A;
      font-size: rem(15px);
      font-weight: 500;
      font-style: italic;
    }
    span {
      display: block;
    }
  }

}

.footer-small{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;

    a, span, div {
      font-size: rem(15px);
      color: #757575;
    }
    a:hover, a:hover span {
      color: $color-main;
    }

    &__realization{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      span{
        transition: color .3s ease-in-out;
      }
      img{
        margin-left: 15px;
      }
    }
}
  
@include mq($until: xl) {
  .footer {
    &__contact {
      grid-column-gap: 30px;
    }
    &__widget {
      a {
        display: block;
        text-align: center;
      }
      img {
        margin-bottom: 20px;
        margin-right: 20px;
      }
    }
  }
}

@include mq($until: md) {
  .footer {
    &__logo {
      margin-bottom: 20px;
      margin-left: auto;
      margin-right: auto;
    }
    &__contact {
      grid-template-columns: 1fr;
      grid-row-gap: 20px;
      justify-items: start;
      margin-top: 60px;
      li.address {
        padding-left: 0;
        text-align: left;
      }
    }
    &__info {
      display: block;
    }
    &__widgets {
      grid-template-columns: 1fr;
      grid-row-gap: 30px;
    }
    &__widget {
      a {
        display: flex;
        text-align: left;
        padding: 0;
        &:hover {
          background-color: unset;
        }
      }
      img {
        margin-bottom: 0;
        margin-right: 20px;
        max-width: 60px;
      }
      span {
        display: inline;
        font-size: 14px;
      }
    }
  }
}


@include mq($until: xs) {
  .footer-small {
    display: block;
    &__realization {
      display: block;
    }
    &__copyright {
      margin-top: 10px;
    }
  }
}
