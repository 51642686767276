.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999;

  &--fixed {
    position: fixed;
    width: 100%;
    transition: background-color .3s ease-in-out;

    &.sticky {
      background-color: lemonchiffon;
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 110px;
    column-gap: 20px;
    a {
        color: #fff;
        text-decoration: none;

    }
  }
  &__widget {
    font-style: italic;
    a {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: rem(15px);
      line-height: rem(15px);
      letter-spacing: -1px;
    }
    img {
      width: 50px;
      margin-right: 20px;
    }
  }
  &__logo {
    position: relative;
    z-index: 2;
    max-width: 182px;
  }
  &__buttons {
    display: flex;
    align-items: center;
  }
  &__nav {
    background: #fff;
    margin-right: 8vw;
    border-radius: 0 5px 5px 0;
    position: relative;

    &.submenu-opened::after {
      bottom: 0;
    }
    &::after {
      content: '';
      position: absolute;
      background: linear-gradient(90deg, #604A25 0%, #B88E47 70%, #BF934A 100%);
      width: 100%;
      height: 8px;
      left: -20px;
      bottom: -8px;
      border-radius: 0 0 5px 0;
      transition: ease 0.3s all;
      z-index: -1;
    }

    &--fullscreen {
      width: 100%;
      height: 100vh;
      background-color: $color-extra;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      padding-top: 100px;
      transform: translate(0, -100%);
      transition: transform 0.3s ease-in-out;
    }
    &--visible {
      transform: translate(0, 0);
    }
  }

  &__menuList {
    list-style: none;
    padding: 0;
    margin: 0;
    &--fullscreen {
      flex-direction: column;
    }
  }
  &__menuItem {
    display: inline-block;
    margin-top: 0;
    padding: 0;
    position: unset;
    &--fullscreen{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
    }

    &--hasSubmenu > a::after {
      content: '';
      background-image: url('/themes/default/assets/images/icons/arrow.svg');
      width: 12px;
      height: 12px;
      display: inline-block;
      margin-left: 15px;
      transition: ease 0.3s transform;
    }

    &::before {
      display: none;
    }
    &.megamenu .header__submenu {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      column-gap: 50px;
      row-gap: 20px;
      left: -20px;
      padding-left: calc(8vw + 40px);
      padding-top: 80px;
      padding-bottom: 80px;
      right: unset;
      top: 100%;
      width: 100%;
      background: linear-gradient(160deg, rgba(96, 74, 37, 0.9) 0%, rgba(184, 143, 71, 0.9) 70%, rgba(191, 146, 74, 0.9) 100%);
      border-radius: 0 0 5px 0;
      span {
        color: #fff;
        font-size: rem(20px);
        font-weight: 700;
        display: block;
      }
      .header__menuItem {
        display: block;
        margin: 10px 0 0 0;
        &.big {
          margin-bottom: 30px;
          margin-top: 0;
        }
        &.menuHeader {
          margin-bottom: 5px;
        }
        &.big .header__menuLink {
          font-size: rem(20px);
          font-weight: 700;
        }
      }
      .header__menuLink {
          padding: 0;
      }
      .column {
        margin: 0;
      }
    }
  }
&__column {
  display: flex;
  flex-direction: column;
}

  &__menuLink {
    color: #161614;
    text-decoration: none;
    padding: 12px 20px;
    display: block;
    font-size: rem(18px);
    font-weight: 400;
    transition: ease 0.3s color;
    &--fullscreen {
      color: #ffffff;
      font-size: rem(28px);
    }

    &:active, &--active, &--open, &:hover {
      color: $color-main;
    }
    &--open::after {
      transform: rotate(180deg);
    }
  }
}
@include mq($until: xl){
  .header {
    &__nav {
      margin-right: 100px;
    }
  }
}
@include mq($until: lg){
  .header {
    &__nav {
      margin-right: 50px;
    }
  }
}

@include mq($until: md){
  .header {
    &__widget {
      display: none;
    }
    &__hamburger {
      margin-left: 40px;
    }
  }
}
@include mq($until: xs){
  .header {
    div#flags {
      margin-left: 0;
      .selected {
        min-width: 70px;
        span {
          display: none;
        }
      }
      .dropdown {
        min-width: 90px;
      }
    }
    &__hamburger {
      margin-left: 20px;
      margin-right: 15px;
    }
  }
}
