.mobile-bar {
    background-color: #161615;
    display: none;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 9;
    @include mq($until: md) {
        display: flex;
    }
    &.icons {
        .mobile-bar__link span {
            display: none;
        }
    }
    .socials {
        display: flex;
    }
    &__link {
        padding: 13px;
        display: flex;
        align-items: center;
        column-gap: 13px;
        transition: ease 0.3s all;
        height: 54px;
        &:hover {
            background-color: $color-main;;
            span {
                color: #161615;
            }
            svg {
                fill: #161615;
            }
        }
        span {
            color: #fff;
        }
        svg {
            width: 25px;
            height: 25px;
            fill: #fff;
            transition: ease 0.3s all;
        }
    }
}
